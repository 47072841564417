











import GmapMixin from '@/mixins/gmapMixin'
import mixins from 'vue-typed-mixins'

export default mixins(GmapMixin).extend( {
    data: ()=> ({
        mapTypeId: window.google.maps.MapTypeId.HYBRID,
        integrateInfoWindows: false,
        enableDrawingManager: false,
        zoom: 20
    }),
})
