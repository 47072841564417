
















































































































































































































































































































































































































import ListCard, { ListCardType } from '@/components/ListCard.vue'
import JumpLinks from '@/components/PropertyJumpLinks.vue'
import SingleGmap from '@/components/SingleGmap.vue'
import { EventBus, ON_SHOW_MAP } from '@/eventbus'
import { cardSettingService, jumpLinksService } from '@/services'
import { CardSetting } from '@/types/CardSettingServiceType'
import { GmapRecordSetting, StackGmapIconSetting } from '@/types/StackGmapServiceType'
import { Page } from '@/types/enums'
import { GmapRecord } from '@/types/gmapMixinType'
import { generateGmapRecord, initGmaps } from '@/utils'
import Vue from 'vue'

export default Vue.extend({
    name: 'PropertyDetail',
    components: {ListCard,JumpLinks,SingleGmap},
    data() {
        return {
            property: {} as any,
            appraisal: {} as any,
            jumpLinkData: [] as {label: string, value: string}[],
            showMap: false,
            gmapOptions: {
                page: Page.PROPERTY
            },
            propertyCardSettings: {
                'propertyInfo': false,
                'appraisalInfo': false,
                'contacts': false,
                'additionalDetails':false,
                'leases': false,
                'appraisalJobDetails': false
            } as Record<string,boolean>,
            gmapRecordSetting: {} as GmapRecordSetting,
            gmapIconSetting: {} as StackGmapIconSetting,
            isMapConfigured: false,
            gmapRecord: [] as GmapRecord[]
        }
    },
    async mounted()  {
        await initGmaps.call(this)
        await this.getProperty()
        const propertyCardSettings = await cardSettingService.getCardSettings(Page.PROPERTY_INFORMATION)
        propertyCardSettings.forEach((cardSetting: CardSetting) => {
            if(cardSetting.name in this.propertyCardSettings) {
                this.propertyCardSettings[cardSetting.name] = cardSetting.visibility
            }
        })
       
        const jumpLinks = await jumpLinksService.getJumpLinks('property')

        this.jumpLinkData = jumpLinks
            .filter((o)=>{
                return !o.parts
                    .filter((part:any)=>part.type==='dropdown')
                    .map((part:any)=>!!part.value)
                    .some((boolean: any)=>!boolean)
            })
            .map((o)=>{
                return {...o,parts:o.parts.map((part: { type: string; value: string|number })=>{

                    if(part.type === 'text') return part.value
                    if(part.type === 'dropdown') return this.property[part.value]
                }).join('')
                }
            }).map((o)=>{
                return {
                    label:o.label,
                    value:o.parts
                }
            })

        this.showMap = true

    },
    methods: {
        async getProperty() {
            const propertyID = this.$route.params.propertyID

            const res = await this.axios.get(`/Property/GetProperty/${propertyID}`)

            this.property = res.data

            this.gmapRecord = [generateGmapRecord({
                recordSetting: this.gmapRecordSetting, 
                dynamicObj: this.property, 
                id: this.property.property,
                gmapIconSetting: this.gmapIconSetting,
                enableUrl: true
            })]
        },
        toggleMap() {
            this.showMap = !this.showMap
        },
    },
    computed: {
        // jumpLinkData():any {
        //     return [
        //         {
        //             label:'Ryan Optimus',
        //             value: (()=>{
        //                 const encodeJumpLinkParams = (params:any)=> {
        //                     return encodeURIComponent(encodeURIComponent(JSON.stringify(params)))
        //                 }

        //                 return `https://www.propertytax-software.com/#/parcelSearch/${encodeJumpLinkParams({
        //                     fields: [{
        //                         Alias:'Business Unit',
        //                         Value:this.property.property,
        //                         Operator: 'exact match'
        //                     }],
        //                     rollYear:dayjs().year().toString()
        //                 })}`
        //             })()
        //         },
        //         {
        //             label:'AR Report',
        //             value:`https://chpaz-iis-sql02.corp.creit.ca/ARReport.php?Property=${this.property.property}`,
        //         },
        //         {
        //             label:'Marketing Brochure',
        //             value:this.property.marketingBrochure
        //         },
        //         {
        //             label:'M-Files',
        //             value:this.property.mFilesUrl
        //         },
        //         {
        //             label:'VTS-Deals',
        //             value:(()=>{
        //                 const id = this.property.vtsPropertyId
        //                 if(!id) return ''

        //                 return `https://app.vts.com/portfolio/deals?properties='${id}'&page=1'`

        //             })()
        //         },
        //         {
        //             label:'VTS-Layout',
        //             value: (()=> {
        //                 const id = this.property.vtsPropertyId
        //                 const product = this.property.product

        //                 if(!id || !product) return ''

        //                 const isRetail = product.toUpperCase() === 'RETAIL'
        //                 const path = isRetail ? 'site' : 'stacking'
        //                 return `https://app.vts.com/portfolio/${path}-plan?properties=${id}`

        //             })()
        //         },
        //         {
        //             label:'VTS-Leases',
        //             value: (()=> {
        //                 const id = this.property.vtsPropertyId
        //                 if(!id) return ''

        //                 return `https://app.vts.com/portfolio/leases?properties=${id}`

        //             })()
        //         },
        //         {
        //             label:'Income Statement BI',
        //             value:(()=>{
        //                 if(!this.property.companyGL) return ''

        //                 return `https://app.powerbi.com/groups/me/apps/66bf63cc-3294-45bb-8d1b-6b5f8e54115c/reports/8fac7cd9-6d98-434c-ba3a-57f3867b043d/ReportSection3de6237ef9fe6ea50a48?filter=businessunit/company eq '${this.property.companyGL}'`
        //             })()
        //         },
        //         {
        //             label:'Unit Inventory BI',
        //             value:(()=>{
        //                 if(!this.property.company) return ''

        //                 return `https://app.powerbi.com/groups/me/apps/66bf63cc-3294-45bb-8d1b-6b5f8e54115c/reports/71438d42-bd58-4320-9d8a-1c2942da289c/ReportSection?filter=AbsorptionQuarterly~2FPROPERTYCODE eq '${this.property.company}'`
        //             })(),
        //         },
        //         {
        //             label:'Rent Roll (Net Change) Report',
        //             value:`https://chpaz-iis-sql02.corp.creit.ca/rentrollreport.php?Property=${this.property.property}`,
        //         },
        //         {
        //             label:'External Webpage En',
        //             value:this.property.leaseListingUrlEn,
        //         },
        //         {
        //             label:'External Webpage Fr',
        //             value:this.property.leaseListingUrlFr,
        //         },
        //         {
        //             label:'Leasing Brochure En',
        //             value:this.property.leasePdfUrlEn,
        //         },
        //         {
        //             label:'Leasing Brochure Fr',
        //             value:this.property.leasePdfUrlFr,
        //         },
        //         {
        //             label:'Roof Leaks',
        //             value:`https://app.powerbi.com/groups/me/reports/76b224c6-9a8e-44f6-a966-6cf9afd08437/ReportSection491b18420448e000e9c4?filter=BU/company eq ${this.property.property}`,
        //         },
        //         ...this.$store.state.setting.jumpLinks
        //             .filter((o:any)=>o.location==='property')
        //             .filter((o:any)=>{
        //                 return !o.parts
        //                     .filter((part:any)=>part.type==='dropdown')
        //                     .map((part:any)=>!!part.value)
        //                     .some((boolean: any)=>!boolean)
        //             })
        //             .map((o: { parts: any[] })=>{
        //                 return {...o,parts:o.parts.map((part: { type: string; value: string|number })=>{

        //                     if(part.type === 'text') return part.value
        //                     if(part.type === 'dropdown') return this.property[part.value]
        //                 }).join('')
        //                 }
        //             }).map((o: { label: any; parts: any })=>{
        //                 return {
        //                     label:o.label,
        //                     value:o.parts
        //                 }
        //             })
        //     ]
        // },
        propertyInfo():ListCardType {
            return [
                {
                    label: 'Company',
                    value: this.property.company,
                    method: 'quickFilter',
                    name:'Company Code'

                },
                {
                    label: 'Correlated Company',
                    value: this.property.correlatedCompanyCode,
                    method:'quickFilter',
                    name:'Correlated Company Code'
                },
                {
                    label:'Business Unit',
                    value:this.property.property,
                },
                {
                    label:'Property Name',
                    value:this.property.propertyName,
                },
                {
                    label:'Address',
                    value: this.property.address,

                },
                {
                    label:'Postal Code',
                    value:this.property.postalCode,
                },
                {
                    label:'City',
                    value:this.property.city,
                    dialog:[
                        {
                            label:'Upper Tier Municipality',
                            value:this.property.upperTierMunicipality,
                        },
                        {
                            label:'Lower Tier Municipality',
                            value:this.property.lowerTierMunicipality,
                        },
                    ],
                    method:'quickFilter',
                    name:'City'
                },
                {
                    label:'Province',
                    value:this.property.province,
                    method: 'quickFilter',
                    name:'Province'
                },
                {
                    label:'County',
                    value:this.property.county,
                    method: 'quickFilter',
                    name:'County'
                },
                {
                    label:'Country',
                    value:this.property.country,
                    method:'quickFilter',
                    name:'Country'

                },
                {
                    label:'Region',
                    value:this.property.region,
                    method:'quickFilter',
                    name:'Region'

                },
                {
                    label:'Asset Class',
                    value:this.property.product,
                    method:'quickFilter',
                    name:'Asset Class'
                },
                {
                    label:'Sub Asset Class',
                    value:this.property.productSubType,
                    method:'quickFilter',
                    name:'Sub Asset Class'
                },
                {
                    label:'Building Classification',
                    value:this.property.buildingClassification,
                    method:'quickFilter',
                    name:'Building Classification'
                },
            ]
        },
        appraisalInfo():ListCardType {
            return [
                {
                    label: 'Property NRA',
                    value: this.property.propertyNRA
                },
                {
                    label: 'Property GBA',
                    value: this.property.propertyGBA
                },
                {
                    label: 'Job Number',
                    value: this.property.jobNumber
                },
                {
                    label: 'Primary Appraiser',
                    value: this.property.primaryAppraiser
                },
                {
                    label:'Role',
                    value:this.property.role
                },
                {
                    label:'Due Date',
                    value:this.property.dueDate
                },
                {
                    label:'Delivery Date',
                    value: this.property.deliveryDate
                },
                {
                    label:'Fee',
                    value:this.property.fee,
                    type: 'currency'
                },
                {
                    label:'Report Format',
                    value:this.property.reportFormat
                },
                {
                    label:'Primary Use',
                    value:this.property.primaryUse
                },
                {
                    label:'Reviewer',
                    value:this.property.reviewer
                },
                {
                    label:'Trainee',
                    value:this.property.trainee
                },
                {
                    label:'Status',
                    value:this.property.status
                }
            ]
        },
        contacts(): ListCardType {
            return [
                {
                    label: 'Leasing Manager',
                    value: this.property.leasingManager,
                    dialog:[
                        {
                            label:'Email',
                            value:this.property?.contacts?.leasingManager?.emailAddress,
                        },
                        {
                            label:'Work Phone',
                            value:this.property?.contacts?.leasingManager?.workPhone,
                        },
                        {
                            label:'Cell Phone',
                            value:this.property?.contacts?.leasingManager?.cellPhone,
                        },
                        {
                            label:'Direct Phone',
                            value:this.property?.contacts?.leasingManager?.directPhone,
                        },
                    ],
                    method:'quickFilter',
                    name:'Leasing Manager'
                },
                {
                    label:'Lease Admin',
                    value:this.property.leaseAdmin,
                    dialog:[
                        {
                            label:'Email',
                            value:this.property?.contacts?.leasingManager?.emailAddress,
                        },
                        {
                            label:'Work Phone',
                            value:this.property?.contacts?.leasingManager?.workPhone,
                        },
                        {
                            label:'Cell Phone',
                            value:this.property?.contacts?.leasingManager?.cellPhone,
                        },
                        {
                            label:'Direct Phone',
                            value:this.property?.contacts?.leasingManager?.directPhone,
                        },
                    ],
                    method:'quickFilter',
                    name:'Lease Admin'
                },
                {
                    label:'Product VP',
                    value:this.property.vicePresident,
                    dialog:[
                        {
                            label:'Email',
                            value:this.property?.contacts?.vicePresident?.emailAddress,
                        },
                        {
                            label:'Work Phone',
                            value:this.property?.contacts?.vicePresident?.workPhone,
                        },
                        {
                            label:'Cell Phone',
                            value:this.property?.contacts?.vicePresident?.cellPhone,
                        },
                        {
                            label:'Direct Phone',
                            value:this.property?.contacts?.vicePresident?.directPhone,
                        },
                    ],
                    method:'quickFilter',
                    name:'Product VP'
                },
                {
                    label:'Property Director',
                    value: this.property.propertyDirector,
                    dialog:[
                        {
                            label:'Email',
                            value:this.property?.contacts?.propertyDirector?.emailAddress,
                        },
                        {
                            label:'Work Phone',
                            value:this.property?.contacts?.propertyDirector?.workPhone,
                        },
                        {
                            label:'Cell Phone',
                            value:this.property?.contacts?.propertyDirector?.cellPhone,
                        },
                        {
                            label:'Direct Phone',
                            value:this.property?.contacts?.propertyDirector?.directPhone,
                        },
                    ],
                    method:'quickFilter',
                    name:'Property Director'
                },
                {
                    label:'Property Manager',
                    value:this.property.propertyManager,
                    dialog:[
                        {
                            label:'Email',
                            value:this.property?.contacts?.propertyManager?.emailAddress,
                        },
                        {
                            label:'Work Phone',
                            value:this.property?.contacts?.propertyManager?.workPhone,
                        },
                        {
                            label:'Cell Phone',
                            value:this.property?.contacts?.propertyManager?.cellPhone,
                        },
                        {
                            label:'Direct Phone',
                            value:this.property?.contacts?.propertyManager?.directPhone,
                        },
                    ],
                    method:'quickFilter',
                    name:'Property Manager'
                },
                {
                    label:'Property Admin',
                    value:this.property.propertyAdmin,
                    dialog:[
                        {
                            label:'Email',
                            value:this.property?.contacts?.propertyAdmin?.emailAddress,
                        },
                        {
                            label:'Work Phone',
                            value:this.property?.contacts?.propertyAdmin?.workPhone,
                        },
                        {
                            label:'Cell Phone',
                            value:this.property?.contacts?.propertyAdmin?.cellPhone,
                        },
                        {
                            label:'Direct Phone',
                            value:this.property?.contacts?.propertyAdmin?.directPhone,
                        },
                    ],
                    method:'quickFilter',
                    name:'Property Admin'
                },
                {
                    label:'Property Accountant',
                    value:this.property.propertyAccountant,
                    dialog:[
                        {
                            label:'Email',
                            value:this.property?.contacts?.propertyAccountant?.emailAddress,
                        },
                        {
                            label:'Work Phone',
                            value:this.property?.contacts?.propertyAccountant?.workPhone,
                        },
                        {
                            label:'Cell Phone',
                            value:this.property?.contacts?.propertyAccountant?.cellPhone,
                        },
                        {
                            label:'Direct Phone',
                            value:this.property?.contacts?.propertyAccountant?.directPhone,
                        },
                    ],
                    method:'quickFilter',
                    name:'Property Accountant'
                },
                {
                    label:'Property Accounting Manager',
                    value:this.property?.propertyAccountingManager,
                    dialog:[
                        {
                            label:'Email',
                            value:this.property.contacts?.propertyAccountingManager?.emailAddress,
                        },
                        {
                            label:'Work Phone',
                            value:this.property.contacts?.propertyAccountingManager?.workPhone,
                        },
                        {
                            label:'Cell Phone',
                            value:this.property.contacts?.propertyAccountingManager?.cellPhone,
                        },
                        {
                            label:'Direct Phone',
                            value:this.property.contacts?.propertyAccountingManager?.directPhone,
                        },
                    ],
                    method:'quickFilter',
                    name:'Property Accounting Manager'
                },
                {
                    label:'Property Group',
                    value:this.property.propertyGroup,
                    dialog:[
                        {
                            label:'Email',
                            value:this.property?.contacts?.propertyGroup?.emailAddress,
                        },
                        {
                            label:'Work Phone',
                            value:this.property?.contacts?.propertyGroup?.workPhone,
                        },
                        {
                            label:'Cell Phone',
                            value:this.property?.contacts?.propertyGroup?.cellPhone,
                        },
                        {
                            label:'Direct Phone',
                            value:this.property?.contacts?.propertyGroup?.directPhone,
                        },
                    ],
                    method:'quickFilter',
                    name:'Property Group'
                },
            ]
        },
        additionalDetails(): ListCardType {
            return [
                {
                    label:'Bill To Entity / Beneficial Owner',
                    value:this.property.billToEntity,
                    dialog:[
                        {
                            label:'GST/HST',
                            value:this.property.billToGSTHST,
                        },
                        {
                            label:'QST/PST',
                            value:this.property.billToPSTQST,
                        },
                    ]
                },
                {
                    label:'Remit To Entity',
                    value:this.property.remitToEntity,
                    dialog:[
                        {
                            label:'GST/HST',
                            value:this.property.remitToGSTHST,
                        },
                        {
                            label:'QST/PST',
                            value:this.property.remitToPSTQST,
                        },
                    ]
                },
                {
                    label:'Nominee / Registered Owner',
                    value:this.property.NomineeRegisteredOwner,
                },
                {
                    label:'Co-Owner',
                    value:this.property.coOwner,
                    dialog:[
                        {
                            label:'IPP Accounting',
                            value:this.property.ippAccounting,
                        },
                        {
                            label:'PUD Accounting',
                            value:this.property.pudAccounting,
                        },
                        {
                            label:'Leasing',
                            value:this.property.leasing,
                        },
                        {
                            label:'Property Management',
                            value:this.property.propertyManagement,
                        },
                    ],
                    method:'quickFilter',
                    name:'Co-Owner',
                },
                {
                    label:'Ownership Percentage',
                    value:this.property.ownershipInterest,
                    type: 'percentage'
                },
                {
                    label:'GLA',
                    value: this.property.gla,
                    type:'number'
                },
                {
                    label:'Owned GLA',
                    value:this.property.ownedGLA,
                    type:'number'
                },
                {
                    label:'Occupancy SQF',
                    value:this.property.occupancySQF,
                    type:'number'
                },
                {
                    label:'Occupancy %',
                    value:this.property.occupancyRate,
                    type: 'percentage'
                },
                {
                    label:'Owned Vacant GLA',
                    value:this.property.vacancySQF,
                    type:'number'
                },
                {
                    label:'Vacancy %',
                    value:this.property.vacancyRate,
                    type: 'percentage'
                },
            ]
        }
    },
    watch: {
        showMap(newValue) {
            if(newValue) {
                EventBus.$emit(ON_SHOW_MAP)
            }
        }
    }
})
